/** @namespace calculateAge*/

/**
 * @method calculateAge
 * @param {Date}  dateOfBirth date of birth
 * @author Daniel Ramos <dramos@inspirebrands.com>
 * @added 2022-09-14
 * @version 1.0
 * @memberOf calculateAge
 * @returns {number} yearsOld
 * @example
 * const AGE_ALLOWED_FOR_BEER = 21;
 * const purchaseBeer = (user: UserInfo) => {
 *     if (calculateAge(user.dateOfBirth) < AGE_ALLOWED_FOR_BEER) {
 *         setError('User not allowed to purchase beer');
 *     }
 *     purchaseBeer();
 * }
 */
export const calculateAge = (dateOfBirth: Date): number => {
    const today = new Date();
    const monthDiff = today.getMonth() - dateOfBirth.getMonth();

    let age = today.getFullYear() - dateOfBirth.getFullYear();

    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < dateOfBirth.getDate())) {
        age--;
    }

    return age;
};
