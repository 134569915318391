import React, { useCallback, useState } from 'react';
import { useField, FieldConfig } from 'formik';
import classNames from 'classnames';
import MaskedInput, { MaskedInputProps } from 'react-text-mask';

import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import InspireTooltip from '../../atoms/Tooltip';
import Icon from '../../atoms/BrandIcon';

import styles from './index.module.css';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';

interface IFormikInput extends FieldConfig<string> {
    className?: string;
    defaultValue?: string;
    description?: string;
    hideDescriptionIfInvalid?: boolean;
    errorClassName?: string;
    inputClassName?: string;
    label: string | JSX.Element;
    labelClassName?: string;
    mask?: Array<RegExp | string>;
    max?: number;
    maxLength?: number;
    onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
    onChange?: (event: React.FocusEvent<HTMLInputElement>) => void;
    onKeyPress?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
    pipe?: MaskedInputProps['pipe'];
    placeholder?: string;
    required?: boolean;
    showErrorImmediately?: boolean;
    disabled?: boolean;
    withTooltip?: boolean;
    tooltipClassName?: string;
    tooltipTitle?: string;
    optional?: boolean;
    multiline?: boolean;
    inputmode?: string;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Input = ({ mask, pipe, multiline, ...props }: any): JSX.Element => {
    if (mask) {
        return <MaskedInput {...props} keepCharPositions mask={mask} pipe={pipe} />;
    }

    if (multiline) {
        return <TextareaAutosize {...props} />;
    }

    return <input {...props} />;
};

const FormikInput = ({
    className,
    description,
    hideDescriptionIfInvalid = false,
    errorClassName,
    inputClassName,
    label,
    labelClassName,
    onKeyPress,
    placeholder,
    required,
    showErrorImmediately,
    validate,
    withTooltip,
    tooltipTitle,
    tooltipClassName,
    optional,
    ...props
}: IFormikInput): JSX.Element => {
    const [shouldShowTooltip, setShouldShowTooltip] = useState<boolean>(false);
    const [field, meta] = useField<string>({ validate, ...props });
    const groupClasses = classNames(styles.inputGroup, {
        [styles.invalid]: (meta.touched || (showErrorImmediately && meta.value)) && meta.error,
        [className]: !!className,
    });

    const handleKeyPress = useCallback(
        (e) => {
            if (typeof onKeyPress === 'function') {
                onKeyPress(e);
            }
        },
        [onKeyPress]
    );
    const labelStar = required ? <span className={styles.inputLabelStar}> *</span> : null;
    const labelOptional = optional ? <span className={styles.inputLabelOptional}> (Optional)</span> : null;
    const isShowDescription = hideDescriptionIfInvalid ? !!description && !meta.error && !!meta.value : !!description;

    return (
        <div className={groupClasses}>
            {!withTooltip ? (
                <label
                    className={classNames(styles.inputLabel, {
                        [labelClassName]: !!labelClassName,
                    })}
                    htmlFor={field.name}
                >
                    {label}
                    {labelStar}
                    {labelOptional}
                </label>
            ) : (
                <div
                    className={classNames(styles.labelGroup, {
                        [labelClassName]: !!labelClassName,
                    })}
                >
                    <label htmlFor={field.name}>
                        {label}
                        {labelStar}
                        {labelOptional}
                    </label>
                    <ClickAwayListener onClickAway={() => setShouldShowTooltip(false)}>
                        <div className={styles.tooltipWrapper}>
                            <InspireTooltip
                                title={tooltipTitle}
                                arrow
                                placement="top"
                                open={shouldShowTooltip}
                                tooltipClassName={tooltipClassName}
                                PopperProps={{
                                    modifiers: {
                                        flip: {
                                            enabled: false,
                                        },
                                    },
                                }}
                            >
                                <div>
                                    <Icon
                                        className={styles.tooltipIcon}
                                        icon="info-question"
                                        size="xs"
                                        ariaLabel={`informational icon ${field.name}`}
                                        onClick={() => setShouldShowTooltip(true)}
                                    />
                                </div>
                            </InspireTooltip>
                        </div>
                    </ClickAwayListener>
                </div>
            )}
            <div>
                <Input
                    aria-required={required}
                    id={field.name}
                    {...field}
                    {...props}
                    placeholder={placeholder}
                    className={classNames(styles.inputControl, {
                        [inputClassName]: !!inputClassName,
                    })}
                    onKeyPress={handleKeyPress}
                />
            </div>
            {(meta.touched || (showErrorImmediately && meta.value)) && meta.error && (
                <div
                    className={classNames(styles.inputError, {
                        [errorClassName]: !!errorClassName,
                    })}
                >
                    {meta.error}
                </div>
            )}
            {isShowDescription && (
                <div className={classNames('t-paragraph-hint', styles.description)}>{description}</div>
            )}
        </div>
    );
};

export default FormikInput;
