import { FieldValidator } from 'formik';
import { calculateAge } from './calculateAge';

import {
    requireValidator,
    charValidator,
    maxLengthValidator,
    minLengthValidator,
    phoneValidator,
    phoneWithDashesValidator,
    emailValidator,
    numberValidator,
    numbersOrDashesValidator,
    requireNumberValidator,
    dateValidator,
    zipCodeValidator,
    requireBooleanValidator,
    atLeastOneSymbolValidator,
    atLeastOneNumberValidator,
    atLeastOneLowercaseValidator,
    atLeastOneUppercaseValidator,
    dateAndYearValidator,
    numericValidatorWithSpecificLength,
    minNumberValidator,
    clainNumberValidator,
    storeNumberValidator,
    numberWithPossibleDecimals,
    nameValidator,
    maxNumberValidator,
    avatarExtensionValidator,
    symbolValidator,
} from './validateHelper';

const MAX_LENGTH = 70;

export const validateClaimNumber = (label: string): FieldValidator => {
    const isClaimNumber = clainNumberValidator(`${label} IS NOT VALID.`);
    return (value: string) => isClaimNumber(value);
};

export const validateStoreNumber = (label: string, messageMap: { require?: string } = {}): FieldValidator => {
    const require = requireValidator(messageMap.require || `${label} is incomplete`);
    const isStoreNumber = storeNumberValidator(`${label} ENTERED IS NOT VALID`);
    return (value: string) => require(value) || isStoreNumber(value);
};

export const validateCheckNumber = (label: string, messageMap: { require?: string } = {}): FieldValidator => {
    const require = requireValidator(messageMap.require || `${label} is incomplete`);
    const isCheckNumber = numericValidatorWithSpecificLength(`${label} ENTERED IS NOT VALID`);
    return (value: string) => require(value) || isCheckNumber(value);
};

export const validateSubTotal = (label: string, messageMap: { require?: string } = {}): FieldValidator => {
    const require = requireValidator(messageMap.require || `${label} is incomplete`);
    const isSubTotal = numberWithPossibleDecimals(`${label} ENTERED IS NOT VALID`);
    return (value: string) => require(value) || isSubTotal(value);
};

export const validateAlpha = (
    label: string,
    max?: number,
    messageMap: { require?: string; char?: string; maxLength?: string } = {}
): FieldValidator => {
    const require = requireValidator(messageMap.require || `${label} is incomplete`);
    const char = charValidator(messageMap.char || 'Alpha characters only');
    const maxLength = maxLengthValidator(
        max || MAX_LENGTH,
        messageMap.maxLength || `No longer than ${max || MAX_LENGTH} characters`
    );

    return (value: string) => require(value) || char(value) || maxLength(value);
};

export const validateName = (
    label: string,
    max?: number,
    messageMap: { require?: string; char?: string; maxLength?: string } = {}
): FieldValidator => {
    const require = requireValidator(messageMap.require || `${label} is incomplete`);
    const char = nameValidator(messageMap.char || 'This value seems to be invalid');
    const maxLength = maxLengthValidator(
        max || MAX_LENGTH,
        messageMap.maxLength || `No longer than ${max || MAX_LENGTH} characters`
    );

    return (value: string) => require(value) || char(value) || maxLength(value);
};

export const validateNonRequireAlpha = (
    max?: number,
    messageMap: { require?: string; char?: string; maxLength?: string } = {}
): FieldValidator => {
    const char = charValidator(messageMap.char || 'Alpha characters only');
    const maxLength = maxLengthValidator(
        max || MAX_LENGTH,
        messageMap.maxLength || `No longer than ${max || MAX_LENGTH} characters`
    );

    return (value: string) => char(value) || maxLength(value);
};

export const validateZipCode = (label: string): FieldValidator => {
    const require = requireNumberValidator(`${label} is incomplete`);
    const numeric = numberValidator('Numeric characters only');
    const validZipCode = zipCodeValidator(`${label} is invalid`);

    return (value: number) => require(value) || numeric(value) || validZipCode(value);
};

export const validateZipCodeWithDash = (label: string): FieldValidator => {
    const require = requireNumberValidator(`${label} is incomplete`);
    const numericAndDashes = numbersOrDashesValidator('Numeric characters only');
    const validZipCode = zipCodeValidator(`${label} is invalid`);

    return (value: string) => require(value) || numericAndDashes(value) || validZipCode(value);
};

export const validateGiftCardNumber = (
    minLength = 19,
    messageMap: { require?: string; min?: string; numeric?: string } = {}
): FieldValidator => {
    const require = requireNumberValidator(messageMap.require || ' ');
    const numeric = numberValidator(messageMap.numeric || ' ');
    const min = minLengthValidator(minLength, messageMap.min || ' ');

    return (value: number) => require(value) || numeric(value) || min(value);
};

export const validateGiftCardPin = (
    required: boolean,
    messageMap: { require?: string; min?: string; numeric?: string } = {}
): FieldValidator => {
    const require = requireNumberValidator(messageMap.require || ' ');
    const numeric = numberValidator(messageMap.numeric || ' ');
    const min = minLengthValidator(4, messageMap.min || ' ');

    if (required) {
        return (value: string) => require(value) || numeric(value) || min(value);
    }
    return () => undefined;
};

export const validatePhone = (
    label: string,
    messageMap: { require?: string; invalid?: string } = {}
): FieldValidator => {
    const require = requireValidator(messageMap.require || `${label} is incomplete`);
    const phone = phoneValidator(messageMap.invalid || 'Incorrect phone');

    return (value: string) => require(value) || phone(value);
};

export const validatePhoneWithDashes = (
    label: string,
    messageMap: { require?: string; invalid?: string } = {}
): FieldValidator => {
    const require = requireValidator(messageMap.require || `${label} is incomplete`);
    const phone = phoneWithDashesValidator(messageMap.invalid || 'Incorrect phone');

    return (value: string) => require(value) || phone(value);
};

export const validatePhoneWithMask = (
    label: string,
    messageMap: { require?: string; invalid?: string } = {}
): FieldValidator => {
    const require = requireValidator(messageMap.require || `${label} is incomplete`);
    const phone = phoneValidator(messageMap.invalid || 'Incorrect phone');

    return (value: string) => {
        const result = value.replace(/[()-\s]/g, '');
        return require(result) || phone(result);
    };
};

export const validateEmail = (label: string): FieldValidator => {
    const require = requireValidator(`${label} is incomplete`);
    const email = emailValidator('Incorrect email');

    return (value: string) => require(value) || email(value);
};

export const validateRequire = (label: string): FieldValidator => {
    const require = requireValidator(`${label} is incomplete`);

    return (value: string) => require(value);
};

export const validateRequireBoolean = (label: string): FieldValidator => {
    const require = requireBooleanValidator(`${label} is incomplete`);

    return (value: boolean) => require(value);
};

export const arbysValidateDateOfBirth = (label: string): FieldValidator => {
    const date = dateValidator(`Incorrect format of ${label} (MM/DD)`);
    return (value: string) => (value !== '' ? date(value) : undefined);
};

export const sdiValidateDateOfBirth = (label: string): FieldValidator => {
    const date = dateAndYearValidator(`Please enter a valid ${label} (MM/DD/YYYY)`);
    const minAge = minNumberValidator(18, `You must be 18 years old to join`);

    return (value: string) => (value !== '' ? date(value) : undefined) || minAge(calculateAge(new Date(value)));
};

export const bwwValidateDateOfBirth = (label: string): FieldValidator => {
    const date = dateAndYearValidator(`Please enter a valid ${label} (MM/DD/YYYY)`);
    const minAge = minNumberValidator(18, 'You must be 18 years old to join');
    const require = requireBooleanValidator(`${label} is incomplete`);

    return (value: string) => (value !== '' ? date(value) : require(value)) || minAge(calculateAge(new Date(value)));
};

const validateDateOfBirthMap = {
    arbys: arbysValidateDateOfBirth,
    bww: bwwValidateDateOfBirth,
    sdi: sdiValidateDateOfBirth,
};

export const validateDateOfBirth = validateDateOfBirthMap[process.env.NEXT_PUBLIC_BRAND] || arbysValidateDateOfBirth;

export const validateRequireDateOfBirth = (
    label: string,
    messageMap: { require?: string; invalid?: string } = {},
    withYear?: boolean
): FieldValidator => {
    const require = requireValidator(messageMap.require || `${label} is incomplete`);

    const date = withYear
        ? dateAndYearValidator(`Incorrect format of ${label} (MM/DD/YYYY)`)
        : dateValidator(`Incorrect format of ${label} (MM/DD)`);
    return (value: string) => require(value) || (value !== '' ? date(value) : undefined);
};

export const validatePasswordComplexity = (): FieldValidator => {
    const symbol = atLeastOneSymbolValidator('At least 1 symbol must be entered (e.g. /#$)');
    const digit = atLeastOneNumberValidator('At least 1 digit must be entered');
    const lower = atLeastOneLowercaseValidator('At least 1 lowercase must be entered');
    const upper = atLeastOneUppercaseValidator('At least 1 uppercase must be entered');
    const max = maxLengthValidator(255, 'Password must be no more than 255 characters');
    const min = minLengthValidator(8, 'Password must be at least 8 characters');

    return (value: string) => min(value) || max(value) || upper(value) || lower(value) || digit(value) || symbol(value);
};

export const validateAvatarImage = (
    maxSize: number,
    arrayOfExtensions: string[],
    messageMap: { size: string; extensions: string }
): FieldValidator => {
    const maxSizeCheck = maxNumberValidator(maxSize, messageMap.size);
    const extensionCheck = avatarExtensionValidator(arrayOfExtensions, messageMap.extensions);

    return ({ size, type }: Blob) => maxSizeCheck(size) || extensionCheck(type);
};

export const validateCardName = () => {
    const symbol = symbolValidator('No special symbols allowed.');

    return (value: string) => symbol(value);
};
