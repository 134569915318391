import React from 'react';
import { useScreen } from '../../../common/hooks/useScreen';

export interface ILogoImageProps {
    urlLogo: string;
    className: string;
    wrapperClassName?: string;
    urlLogoDesktop?: string;
    alt?: string;
}

const Logo = ({ urlLogo, urlLogoDesktop, className, wrapperClassName, alt = '' }: ILogoImageProps): JSX.Element => {
    const { isDesktop } = useScreen();
    const imageURL = isDesktop && urlLogoDesktop ? urlLogoDesktop : urlLogo;
    return (
        <picture className={wrapperClassName}>
            <img className={className} src={imageURL} alt={alt} />
        </picture>
    );
};

export default Logo;
