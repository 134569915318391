import { FieldValidator } from 'formik';

export const createPatternValidator = (pattern: RegExp, errorMessage: string): FieldValidator => {
    return (value: string) => (pattern.test(value) ? undefined : errorMessage);
};

export const requireValidator = (errorMessage: string): FieldValidator => (value: string) =>
    value.trim() === '' ? errorMessage : undefined;

export const optionalValidator = (validator: FieldValidator): FieldValidator => (value: string) =>
    value ? validator(value) : undefined;

export const requireBooleanValidator = (errorMessage: string): FieldValidator => (value: boolean) =>
    !value ? errorMessage : undefined;

export const requireNumberValidator = (errorMessage: string): FieldValidator => (value: number) =>
    !value ? errorMessage : undefined;

export const maxNumberValidator = (maxNumber: number, errorMessage: string): FieldValidator => (value: number) =>
    value > maxNumber ? errorMessage : undefined;

export const minNumberValidator = (minNumber: number, errorMessage: string): FieldValidator => (value: number) =>
    value < minNumber ? errorMessage : undefined;

export const maxLengthValidator = (maxLength: number, errorMessage: string): FieldValidator => (value: string) =>
    value.trim().length > maxLength ? errorMessage : undefined;

export const minLengthValidator = (minLength: number, errorMessage: string): FieldValidator => (value: string) =>
    value.trim().length < minLength ? errorMessage : undefined;

export const emailValidator = (errorMessage: string): FieldValidator =>
    createPatternValidator(/^\w+([-+.]\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/, errorMessage);

export const phoneWithDashesValidator = (errorMessage: string): FieldValidator =>
    createPatternValidator(/^[0-9]{3}(-[0-9]{3})(-[0-9]{4})$/, errorMessage);

export const phoneValidator = (errorMessage: string): FieldValidator =>
    createPatternValidator(/^[0-9]{10}$/, errorMessage);

export const zipCodeValidator = (errorMessage: string): FieldValidator =>
    createPatternValidator(/^[0-9]{5}(?:-[0-9]{4})?$/, errorMessage);

export const symbolValidator = (errorMessage: string): FieldValidator =>
    createPatternValidator(/^([\sa-zA-Z0-9]+)?$/, errorMessage);

export const charValidator = (errorMessage: string): FieldValidator =>
    createPatternValidator(/^([\sa-zA-Z]+)?$/, errorMessage);

export const nameValidator = (errorMessage: string): FieldValidator =>
    createPatternValidator(/^([\sa-zA-Z-'‘’`]+)?$/, errorMessage);

export const numberValidator = (errorMessage: string): FieldValidator =>
    createPatternValidator(/^([0-9]+)?$/, errorMessage);

export const numbersOrDashesValidator = (errorMessage: string): FieldValidator =>
    createPatternValidator(/^([0-9,-]+)?$/, errorMessage);

export const dateValidator = (errorMessage: string): FieldValidator =>
    createPatternValidator(/^((((0)[1-9])|((1)[0-2]))([/-])(((0)[1-9])|[1-2][0-9]|(3)[0-1]))$/, errorMessage);

export const dateAndYearValidator = (errorMessage: string): FieldValidator =>
    createPatternValidator(
        /^((((0)[1-9])|((1)[0-2]))([/-])(((0)[1-9])|[1-2][0-9]|(3)[0-1]))([/-])(19\d{2}|20\d{2})$/,
        errorMessage
    );

export const uppercaseValidator = (errorMessage: string): FieldValidator =>
    createPatternValidator(/^([\sA-Z]+)?$/, errorMessage);

export const lowercaseValidator = (errorMessage: string): FieldValidator =>
    createPatternValidator(/^([\sa-z]+)?$/, errorMessage);

export const atLeastOneLowercaseValidator = (errorMessage: string): FieldValidator =>
    createPatternValidator(/[a-z]+/, errorMessage);

export const atLeastOneUppercaseValidator = (errorMessage: string): FieldValidator =>
    createPatternValidator(/[A-Z]+/, errorMessage);

export const atLeastOneNumberValidator = (errorMessage: string): FieldValidator =>
    createPatternValidator(/\d+/, errorMessage);

export const atLeastOneSymbolValidator = (errorMessage: string): FieldValidator =>
    createPatternValidator(/[^\sA-Za-z0-9]+/, errorMessage);

export const clainNumberValidator = (errorMessage: string): FieldValidator =>
    createPatternValidator(/^([0-9]{4})-([0-9]{6})-([0-9]{1,8})-([0-9]{1,8})$/, errorMessage);

export const storeNumberValidator = (errorMessage: string): FieldValidator =>
    createPatternValidator(/^[0-9]{4}$/, errorMessage);

export const numberWithPossibleDecimals = (errorMessage: string): FieldValidator =>
    createPatternValidator(/^\d{1,5}\.?\d{0,2}$/, errorMessage);

export const numericValidatorWithSpecificLength = (errorMessage: string): FieldValidator =>
    createPatternValidator(/^\d{1,8}$/, errorMessage);

export const avatarExtensionValidator = (arrayOfExtensions: string[], errorMessage: string): FieldValidator => (
    value: string
) => (!arrayOfExtensions.includes(value) ? errorMessage : undefined);
